import * as jose from "jose"
import * as keyHelper from "./key.helper"


const toJWK = async (secret, algorithm)  => {
  let jwk = JSON.parse(secret)

  if (!secret) {
    return null
  }

  return jose.importJWK(jwk, algorithm)
}

const fromSPKI = async (key, algorithm) => {  
  
  if (!key) {
    return null
  }

  return jose.importSPKI(key, algorithm)
}

const fromPKCS8 = async (key, algorithm) => {

  if (!key) {
    return null
  }

  return jose.importPKCS8(key, algorithm)
}

const toJSON = (text) => {
  let _result=null
  try {
    _result = JSON.parse(text)
  } catch (e) {
    _result = {"text": text}
  }
  return _result
}

const signJWT = (key, form, text) => {
  return new jose.SignJWT(text)
    .setProtectedHeader(form.header)
    .sign(key)
    .then((token) => {
      return token
    })
}

const encryptJWE = (key, form, text) => {
  return new jose.EncryptJWT(text)
    .setProtectedHeader(form.header)
    .encrypt(key)    
    .then((token) => {
      return token
    })
}

const verifyJWT = (key, token) => {
  return jose.jwtVerify(token, key)
    .then(({payload}) => { 
      return {payload}
    })
}

const decryptJWE = (key, token) => {
  return jose.jwtDecrypt(token, key)
    .then(({payload}) => {
      return {payload}
    })
  
}

const decode = async ({credential, form, token}) => {
    
    let secret = await toJWK(credential.secret, credential.alg)
    let publicKey = await fromSPKI(credential.publicKey, credential.alg)
    let privateKey = await fromPKCS8(credential.privateKey, credential.alg)

    let generateFunc = null;
    let key = null
    if (keyHelper.isSigner(credential.usages)) {
      generateFunc = verifyJWT
    } else if (keyHelper.isEncrypter(credential.usages)) {
      generateFunc = decryptJWE
    }

    if (secret) {
      key = secret
    } else if (privateKey && keyHelper.decryptsOrSigns(privateKey)) {
      key = privateKey
    } else if (publicKey && keyHelper.decryptsOrSigns(publicKey)) {
      key = publicKey
    }

    if (!generateFunc || !key) {
      return new Promise((resolve) => resolve(''))
    }

    return generateFunc(key, token)
      .then(({payload}) => payload)
}

const createToken = async ({ credential, form, payload }) => {

    if (!credential.available) {
      return new Promise((resolve) => resolve(''))
    }

    let text = toJSON(payload)
    let secret = await toJWK(credential.secret, credential.alg)
    let publicKey = await fromSPKI(credential.publicKey, credential.alg)
    let privateKey = await fromPKCS8(credential.privateKey, credential.alg)

    let generateFunc = null;
    let key = null
    if (keyHelper.isSigner(credential.usages)) {
      generateFunc = signJWT
    } else if (keyHelper.isEncrypter(credential.usages)) {
      generateFunc = encryptJWE
    }

    if (secret) {
      key = secret
    } else if (privateKey && keyHelper.encryptsOrSigns(privateKey)) {
      key = privateKey
    } else if (publicKey && keyHelper.encryptsOrSigns(publicKey)) {
      key = publicKey
    }

    if (!generateFunc || !key) {
      return new Promise((resolve) => resolve(''))
    }

    return generateFunc(key, form, text)
    
}



export { createToken, toJWK, fromSPKI, decode }
