import { Select } from '@radix-ui/themes'
import * as keyHelper from "../services/key.helper"

export function JweAlgorithmSelect({ defaultValue, value, onValueChange }) {
    return (
      <Select.Root
        defaultValue={defaultValue}
        value={value}
        onValueChange={onValueChange}
      >
        <Select.Trigger />
        <Select.Content>
          {
            Object.keys(keyHelper.JWE_ALGORITHM_DETAIL)
              .filter((key) => keyHelper.JWE_ALGORITHM_DETAIL[key].available).map((key, index) => (
              <Select.Item key={index} value={key}>{keyHelper.JWE_ALGORITHM_DETAIL[key].key.alg}</Select.Item>
            ))
          }
        </Select.Content>
      </Select.Root>
    )
  }