import { Flex, Heading, TextArea } from "@radix-ui/themes"
import { useSelector } from "react-redux"

export const JWHeader = () => {

    const { form } = useSelector(store => store.keyDispatcher)
    
    let header = ""
    if (form.header) {
        header = JSON.stringify(form.header, null, "\t")
    } else {
        header = 'Algorithm JWT / JWE no usable!!!'
    }
    
    return (
        <Flex direction="column" minWidth="20em"> 
            <Heading _as="h2">Header</Heading>
            <TextArea value={header} readOnly={true} rows={5}></TextArea>
        </Flex>
    )
}