import { Flex, Heading, TextArea } from "@radix-ui/themes"
import { useSelector } from "react-redux"


export const JsonTargetArea = () => {

    const {form} = useSelector(store => store.keyDispatcher)

    /*
    if (jweSecret.pubKey) {
        const jwt = await new jose.EncryptJWT({ 'urn:example:claim': true })
        .setProtectedHeader({ alg: jweDetail['jwe']['alg'], enc: jweDetail['jwe']['enc'] })
        .setIssuedAt()
        .setIssuer('urn:example:issuer')
        .setAudience('urn:example:audience')
        .setExpirationTime('2h')
        .encrypt(jweSecret.pubKey)
        console.log("(PubKey) jwt:", jwt)
        console.log("(PrivKey) decrypt:", await jose.jwtDecrypt(jwt, jweSecret.privKey))
    }

    if (jweSecret.secret) {
        const jwt = await new jose.EncryptJWT({ 'urn:example:claim': true })
        .setProtectedHeader({ alg: jweDetail['jwe']['alg'], enc: jweDetail['jwe']['enc'] })
        .setIssuedAt()
        .setIssuer('urn:example:issuer')
        .setAudience('urn:example:audience')
        .setExpirationTime('2h')
        .encrypt(jweSecret.secret)
        console.log("(Secret) jwt:", jwt)
        console.log("(Secret) decrypt:", await jose.jwtDecrypt(jwt, jweSecret.secret))
    }
    

    }
    */

    return (
        <Flex direction="column" gap="3" width="35%">
            <Heading as="h2">Token</Heading>
            <TextArea readOnly={true} style={{height: "100%"}} value={form.token}></TextArea>
        </Flex>
    )
}