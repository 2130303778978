import { CopyIcon } from "@radix-ui/react-icons"
import { Button, Dialog, Flex, Heading, TextArea } from "@radix-ui/themes"
import copy from "copy-to-clipboard"
import { useState } from "react"

import { useSelector } from "react-redux"

// class CredentialError extends Error { }


const copiedLabel = "Copied!!!"

let secretTimeoutId = null
let publicKeyTimeoutId = null
let privateKeyTimeoutId = null

const SecretArea = ({ value }) => {
  if (!value) {
    return
  }

  let json = null
  try {
    json = JSON.parse(value)
  } catch(e) {
    return
  }

  const k = json.k
  if (!k) {
    return
  }

  return (
    <TextArea disabled={true} value={k}></TextArea>
  )
}

const SecretDialogClose = ({ value }) => {

  const defaultCopyLabel = 'Copy Secret'

  const [copyText, setCopyText] = useState(defaultCopyLabel)
  const [copyDisabled, setCopyDisabled] = useState(false)

  const copyAndClose = (e) => {
    e.preventDefault()
    copy(value)
    setCopyText(copiedLabel)
    setCopyDisabled(true)

    if (secretTimeoutId) {
      clearTimeout(secretTimeoutId)
      secretTimeoutId = null
    }

    secretTimeoutId = setTimeout(() => {
      setCopyText(defaultCopyLabel)
      setCopyDisabled(false)
    }, 750);
  }  

  if (!value) {
    return
  }

  return (
    <Dialog.Close>
      <Button onClick={copyAndClose} disabled={copyDisabled} >
        <CopyIcon alignmentBaseline='middle' /> {copyText}
      </Button>
    </Dialog.Close>
  )
}

const PublicArea = ({ value }) => {
  if (!value) {
    return
  }

  return (
    <>
      <Heading as="h2" size="4">Public Key</Heading>
      <TextArea disabled={true} value={value}></TextArea>
    </>
  )
}

const PublicKeyDialogClose = () => {

  const { credential } = useSelector(state => state.keyDispatcher)

  const defaultCopyLabel = "Copy Public Key"

  const [copyText, setCopyText] = useState(defaultCopyLabel)
  const [copyDisabled, setCopyDisabled] = useState(false)

  const copyAndClose = (e) => {
    e.preventDefault()
    copy(credential.publicKey)
    setCopyText(copiedLabel)
    setCopyDisabled(true)

    if (publicKeyTimeoutId) {
      clearTimeout(publicKeyTimeoutId)
      publicKeyTimeoutId = null
    }

    publicKeyTimeoutId = setTimeout(() => {
      setCopyText(defaultCopyLabel)
      setCopyDisabled(false)
    }, 750);
  }

  if (!credential.publicKey) {
    return
  }

  return (
    <Dialog.Close>
      <Button onClick={copyAndClose} disabled={copyDisabled} >
        <CopyIcon alignmentBaseline='middle' /> {copyText}
      </Button>
    </Dialog.Close>
  )
}

const PrivateArea = ({ value }) => {

  if (!value) {
    return
  }

  return (
    <>
      <Heading as="h2" size="4">Private Key</Heading>
      <TextArea disabled={true} value={value}></TextArea>
    </>
  )
}

const PrivateKeyDialogClose = ({ value }) => {

  const defaultCopyLabel = "Copy Private Key"

  const [copyText, setCopyText] = useState(defaultCopyLabel)
  const [copyDisabled, setCopyDisabled] = useState(false)

  const copyAndClose = (e) => {
    e.preventDefault()
    copy(value)
    setCopyText(copiedLabel)
    setCopyDisabled(true)

    if (privateKeyTimeoutId) {
      clearTimeout(privateKeyTimeoutId)
      privateKeyTimeoutId = null
    }

    privateKeyTimeoutId = setTimeout(() => {
      setCopyText(defaultCopyLabel)
      setCopyDisabled(false)
    }, 750);
  }

  if (!value) {
    return
  }

  return (
    <Dialog.Close>
      <Button onClick={copyAndClose} disabled={copyDisabled} >
        <CopyIcon alignmentBaseline='middle' /> {copyText}
      </Button>
    </Dialog.Close>
  )
}


export function CredentialDialog() {

  const { credential } = useSelector(state => state.keyDispatcher)

  const [open, setOpen] = useState(false)

  const onOpenChange = (open) => {
    setOpen(open)
    if (open) {
      return
    }

    if (secretTimeoutId) {
      clearTimeout(secretTimeoutId)
      secretTimeoutId = null
    }

    if (publicKeyTimeoutId) {
      clearTimeout(publicKeyTimeoutId)
      publicKeyTimeoutId = null
    }

    if (privateKeyTimeoutId) {
      clearTimeout(privateKeyTimeoutId)
      privateKeyTimeoutId = null
    }

  }

  


  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange} >
      <Dialog.Trigger>
        <Button disabled={!credential.available}>View credential</Button>
      </Dialog.Trigger>
      <Dialog.Content>
        <Dialog.Title>Credential</Dialog.Title>
        <Dialog.Description>
          This is the generated credential
        </Dialog.Description>

        <Flex my="5" direction="column" align="stretch">
          <SecretArea value={credential.secret} />
          <PublicArea value={credential.publicKey} />
          <PrivateArea value={credential.privateKey} />
        </Flex>

        <Flex gap="3" justify="end">
          <SecretDialogClose value={credential.secret} />
          <PublicKeyDialogClose value={credential.publicKey} />
          <PrivateKeyDialogClose value={credential.privateKey} />
          <Dialog.Close>
            <Button variant="soft">
              Close
            </Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  )
}