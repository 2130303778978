import * as jose from "jose"
import * as keyHelper from "./key.helper"

const buildHeader = (algorithm, type, enc=null) => {

    let header = {}
    header = {
        ...header,
        alg: algorithm,
        typ: type
    }

    if (enc) {
        header = {
            ...header,
            enc
        }
    }

    return header

}

const generateCredential = async (detail, encryption=null) => {
    let promise = null
    if (detail.key.asymetric) {
        promise = new Promise((resolve) => {
            jose.generateKeyPair(detail.key.alg, { extractable: true }).then(pair => {     
                jose.exportSPKI(pair.publicKey).then(
                    pubKey => {
                        jose.exportPKCS8(pair.privateKey).then(
                            privKey => {
                                let typ = null
                                if (keyHelper.isJWT(pair.publicKey.usages) || keyHelper.isJWT(pair.privateKey.usages)) {
                                    typ = 'JWT'
                                } else if (keyHelper.isJWE(pair.publicKey.usages) || keyHelper.isJWE(pair.privateKey.usages)) {
                                    typ = 'JWE'
                                }
                                resolve({
                                    form: {
                                        header: buildHeader(detail.key.alg, typ, encryption),
                                        usages: [...pair.publicKey.usages.map(item => `public_key_${item}`),  
                                            ...pair.privateKey.usages.map(item => `private_key_${item}`)],
                                    },
                                    credential: {
                                        available: true,
                                        alg: detail.key.alg,
                                        secret: null,
                                        typ,
                                        usages: [ ...pair.publicKey.usages, ...pair.privateKey.usages],
                                        privateKey: privKey,
                                        publicKey: pubKey
                                    }
                                })
                        })                 
                    }
                  )
            })
        })
    } else {
        promise = new Promise((resolve) => {
            jose.generateSecret(detail.key.alg, { extractable: true }).then(secret => {
                jose.exportJWK(secret).then(
                    value => {
                        let typ = null
                        if (keyHelper.isJWT(secret.usages)) {
                            typ = 'JWT'
                        } else if (keyHelper.isJWE(secret.usages)) {
                            typ = 'JWE'
                        }
                        resolve({
                            form: {
                                header: buildHeader(detail.key.alg, typ, encryption),
                                usages: secret.usages.map(item => `secret_${item}`),
                            },
                            credential: {
                                available: true,
                                alg: detail.key.alg,
                                typ,
                                secret: JSON.stringify(value),
                                usages: secret.usages,
                                privateKey: null,
                                publicKey: null
                            }
                        })
                    }
                  )
                
            })
        })

    }
    return promise
}

const createSign = async (algorithm) => {
    return generateCredential(keyHelper.JWS_ALGORITHM_DETAIL[algorithm])
}

const createEncrypt = async ({algorithm, encryption}) => {
    return generateCredential(keyHelper.JWE_ALGORITHM_DETAIL[algorithm], encryption)
}


export { createSign, createEncrypt }
