import { Flex, RadioGroup } from '@radix-ui/themes'
import { useDispatch, useSelector } from 'react-redux'

import * as keyDispatcherReducer from '../redux/reducers/key-dispatcher-reducer'

const GOALS = {
    'sign': 'Sign Key',
    'encrypt': 'Encrypt Key'
}

export function GoalRadioGruop() {

    const dispatch = useDispatch()
    const keyDispatcher = useSelector(store => store.keyDispatcher)

    const onValueChange = (newValue) => {
        dispatch(keyDispatcherReducer.setGoal(newValue))
        if ('sign' === newValue) {
            dispatch(keyDispatcherReducer.setJwsData({
                jws: { algorithm: keyDispatcherReducer.jwsDefaultAlgorithm }
            }))
        } else if ('encrypt' === newValue) {
            dispatch(keyDispatcherReducer.setJweData({
                algorithm: keyDispatcherReducer.jweDefaultAlgorithm,
                encryption: keyDispatcherReducer.jweDefaultEncryption
            }))
        }
    }

    return (
        <Flex direction="row" asChild gap="2">
            <RadioGroup.Root defaultValue={keyDispatcherReducer.defaultGoal} onValueChange={onValueChange}>
                {
                    Object.keys(GOALS).map((key, index) => (
                        <RadioGroup.Item key={index} value={key}>{GOALS[key]}</RadioGroup.Item>
                    ))
                }
            </RadioGroup.Root>
        </Flex>
    )
}