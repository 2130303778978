import './App.css';
import { Box, Button, Container, Flex, Heading, Section, Text } from '@radix-ui/themes'
import * as Form from '@radix-ui/react-form'

import { GoalRadioGruop } from './components/goal-radiogroup';
import { JweAlgorithmSelect } from './components/jwe-algorithm-select'
import { JwsAlgorithmSelect } from './components/jws-algorithm-select'
import { CredentialDialog } from './components/credential-dialog';
import { JsonSourceArea } from './components/json-source';
import { JsonTargetArea } from './components/json-target';
import { UsageTable } from './components/usage-table'
import { useDispatch, useSelector } from 'react-redux';
import * as keyDispatcherReducer from './redux/reducers/key-dispatcher-reducer'


import { JweEncryptionSelect } from './components/jwe-encryption-select';
import { LockClosedIcon } from '@radix-ui/react-icons';
import { JWHeader } from './components/jw-header';
import { DecodingArea } from './components/decoding';


// class GoalError extends Error { }


const JweAlgorithmBox = () => {

  const dispatch = useDispatch()
  const { form } = useSelector(store => store.keyDispatcher)

  const onValueChangeAlgorithm = (newValue) => {
    dispatch(keyDispatcherReducer.setJweData({
      ...form.jwe,
      algorithm: newValue
    }))
    dispatch(keyDispatcherReducer.reset('credential'))
    dispatch(keyDispatcherReducer.reset('encoding'))
  }

  const onValueChangeEncryption = (newValue) => {
    dispatch(keyDispatcherReducer.setJweData({
      ...form.jwe,
      encryption: newValue
    }))
    dispatch(keyDispatcherReducer.reset('credential'))
    dispatch(keyDispatcherReducer.reset('encoding'))
  }

  if (form.goal !== 'encrypt') {
    return
  }

  return (
    <Flex direction="row" gap="3">
      <Box>
        <Text as="div" wrap="nowrap" weight="medium">Algorithm</Text>
        <JweAlgorithmSelect
          defaultValue={keyDispatcherReducer.jweDefaultAlgorithm}
          value={form.jwe.algorithm}
          onValueChange={onValueChangeAlgorithm} />
      </Box>
      <Box>
        <Text as="div" wrap="nowrap" weight="medium">Encryption</Text>
        <JweEncryptionSelect
          defaultValue={keyDispatcherReducer.jweDefaultEncryption}
          value={form.jwe.encryption}
          onValueChange={onValueChangeEncryption} />
      </Box>
    </Flex>

  )
}

const JwsAlgorithmBox = () => {

  const dispatch = useDispatch()
  const { form } = useSelector(store => store.keyDispatcher)

  const onValueChange = (newValue) => {
    dispatch(keyDispatcherReducer.setJwsData({
      algorithm: newValue
    }))
    dispatch(keyDispatcherReducer.reset('credential'))
    dispatch(keyDispatcherReducer.reset('encoding'))
  }

  if (form.goal !== 'sign') {
    return
  }

  return (
    <Box minWidth="12em">
      <Text as="div" wrap="nowrap" weight="medium">Algorithm</Text>
      <JwsAlgorithmSelect
        defaultValue={keyDispatcherReducer.jwsDefaultAlgorithm}
        value={form.jws.algorithm}
        onValueChange={onValueChange} />
    </Box>
  )
}

const UsageTableBox = () => {

  return (
    <Box>
      <UsageTable />
    </Box>
  )
}


const KeyForm = () => {

  const dispatch = useDispatch()
  const { form, credential } = useSelector(store => store.keyDispatcher)

  const generate = (e) => {
    e.preventDefault()
    if ('sign' === form.goal) {
      dispatch(keyDispatcherReducer.createSign({algorithm: form.jws.algorithm, payload: form.payload}))
    }
    if ('encrypt' === form.goal) {
      dispatch(keyDispatcherReducer.createEncrypt({jwe: form.jwe, payload: form.payload}))
    }

  }

  const reset = (e) => {
    e.preventDefault()
    dispatch(keyDispatcherReducer.reset(['form', 'credential', 'encoding']))
  }

  return (
    <Form.Root>
      <Flex direction="column" gap="3" align="start">
        <GoalRadioGruop />
        <JwsAlgorithmBox />
        <JweAlgorithmBox />
        
        <Flex direction="row" gap="3" mt="4" mb="4">
          <Button onClick={generate}>Generate!!!</Button>
          <Button onClick={reset} variant="soft">Reset</Button>
          <Box>
            <CredentialDialog />
          </Box>
        </Flex>
        <JWHeader />
        <UsageTableBox />
      </Flex>
    </Form.Root>
  );
}

function App() {
  return (
    <>
      <Section id="navigation-bar" size="1">
        <Flex direction="row" gap="8">
          <Box style={{ paddingLeft: "12px" }}>
            <LockClosedIcon width="30" height="30" color="white" />
          </Box>
          <Heading style={{ "color": "white" }}>Key Dispatcher</Heading>
        </Flex>
      </Section>
      <Container>
        <Section id="content" size="1">
          <Flex direction="row" gap="4">
            <JsonSourceArea />
            <JsonTargetArea />
            <Flex direction="column" gap="3" width="30%">
              <KeyForm />
            </Flex>
          </Flex>
        </Section>
      </Container>
    </>
  )
}

export default App;
