import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import * as credentialService from '../../services/credential.service'
import * as encodingService from '../../services/encoding.service'

const jwsDefaultAlgorithm = "ps512";
const jweDefaultAlgorithm = "rsa-oaep-512"
const jweDefaultEncryption = "A256CBC-HS512"
const defaultGoal = 'sign'

const initialState = {
    form: {
        goal: 'sign',
        jws: {
            algorithm: jwsDefaultAlgorithm
        },
        jwe: {
            algorithm: jweDefaultAlgorithm,
            encryption: jweDefaultEncryption
        },
        usages: [],
        header: '',
        payload: '',
        token: '',
        decoded: ''
    },
    credential: {
        available: false,
        secret: null,
        publicKey: null,
        privateKey: null,
        usages: []
    },

    process: 'idle'
}

const createToken = createAsyncThunk(
    'encode/generate/token',
    async ({ credential, form, payload }) => {
        let p = encodingService.createToken({ credential, form, payload })
        return new Promise((resolve) => {
            p.then((token) => {
                resolve({
                    credential,
                    form,
                    token
                })
            })
        })
    }
)

const createSign = createAsyncThunk(
    'credential/create/sign',
    async ({ algorithm, payload }) => {
        let p = credentialService.createSign(algorithm)
        if (!payload) {
            return p
        }

        return new Promise((resolve) => {
            p.then(({ form, credential }) => {
                encodingService.createToken({
                    credential,
                    form,
                    payload
                }).then((token) => {
                    resolve({
                        credential,
                        form,
                        token
                    })
                })
            })
        })

    }
)

const createEncrypt = createAsyncThunk(
    "credential/create/encrypt",
    async ({ jwe, payload }) => {
        let p = credentialService.createEncrypt(jwe)
        if (!payload) {
            return p
        }

        return new Promise((resolve) => {
            p.then(({ form, credential }) => {
                encodingService.createToken({
                    credential,
                    form,
                    payload
                }).then((token) => {
                    resolve({
                        credential,
                        form,
                        token
                    })
                })
            })
        })
    }
)




const keyDispatcherSlice = createSlice({
    name: 'keyDispatcher',
    initialState,
    reducers: {
        reset(state, action) {
            if (action.payload.includes('credential')) {
                state.credential = {}
                for (let i in initialState.credential) {
                    state.credential[i] = initialState.credential[i]
                }
            }
            if (action.payload.includes('encoding')) {
                state.encoding = {}
                for (let i in initialState.encoding) {
                    state.encoding[i] = initialState.encoding[i]
                }
            }
            if (action.payload.includes('form')) {
                state.form = {}
                for (let i in initialState.form) {
                    state.form[i] = initialState.form[i]
                }
            }

        },
        setGoal(state, action) {
            state.form.goal = action.payload
        },
        setJweData(state, action) {
            state.form.jwe = action.payload
        },
        setJwsData(state, action) {
            state.form.jws = action.payload
        },
        setPayload(state, action) {
            state.form.payload = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createSign.pending, (state, action) => {
            state.process = 'calculating'
        })
        builder.addCase(createSign.fulfilled, (state, action) => {

            state.credential.secret = null
            state.credential.publicKey = null
            state.credential.privateKey = null

            for (let i in action.payload.credential) {
                state.credential[i] = action.payload.credential[i]
            }

            for (let i in action.payload.form) {
                state.form[i] = action.payload.form[i]
            }

            if (action.payload.token) {
                state.form.token = action.payload.token
            }

            if (action.payload.content) {
                state.form.content = action.payload.content
            }

            state.process = 'done'
        })
        builder.addCase(createEncrypt.pending, (state, action) => {
            state.process = 'calculating'
        })
        builder.addCase(createEncrypt.fulfilled, (state, action) => {

            state.credential.secret = null
            state.credential.publicKey = null
            state.credential.privateKey = null

            for (let i in action.payload.credential) {
                state.credential[i] = action.payload.credential[i]
            }

            for (let i in action.payload.form) {
                state.form[i] = action.payload.form[i]
            }

            if (action.payload.token) {
                state.form.token = action.payload.token
            }

            state.process = 'done'
        })
        builder.addCase(createToken.pending, (state, action) => {
            state.process = 'creating'
        })
        builder.addCase(createToken.fulfilled, (state, action) => {

            if (action.payload.token) {
                state.form.token = action.payload.token
            }

            if (action.payload.content) {
                state.form.content = action.payload.content
            }
        })
    }
})





const { reducer } = keyDispatcherSlice
const keyDispatcherReducer = reducer

const { reset, setGoal, setJwsData, setJweData, setPayload } = keyDispatcherSlice.actions

export { jwsDefaultAlgorithm, jweDefaultAlgorithm, jweDefaultEncryption, defaultGoal }
export { reset, setGoal, setJwsData, setJweData, setPayload }

export { createSign, createEncrypt, createToken }

export default keyDispatcherReducer