
const KEY_USAGES = [
    {text: 'sign', value: 'sign', available: true},
    {text: 'verify', value: 'verify', available: true},
    {text: 'encrypt', value: 'encrypt', available: true},
    {text: 'decrypt', value: 'decrypt', available: true},
    {text: 'deriveKey', value: 'deriveKey', available: false},
    {text: 'deriveBits', value: 'deriveBits', available: false},
    {text: 'wrap', value: 'wrapKey', available: false},
    {text: 'unwrap', value: 'unwrapKey', available: false}
  ]


const JWE_ENC_ALGORITHM_DETAIL = {
    'A128CBC-HS256': {
        available: true
    },
    'A192CBC-HS384': {
        available: false
    },
    'A256CBC-HS512': {
        available: true
    },
    'A128GCM': {
        available: true
    },
    'A192GCM': {
        available: false
    },
    'A256GCM': {
        available: true
    }
  }


const JWS_ALGORITHM_DETAIL = {
    'hs256': {
        available: true,
        key: { alg: 'HS256', asymetric: false }
    },
    'hs384': {
        available: true,
        key: { alg: 'HS384', asymetric: false }
    },
    'hs512': {
        available: true,
        key: { alg: 'HS512', asymetric: false }
    },
    'es256': {
        available: true,
        key: { alg: 'ES256', asymetric: true }
    },
    'es384': {
        available: true,
        key: { alg: 'ES384', asymetric: true }
    },
    'es512': {
        available: true,
        key: { alg: 'ES512', asymetric: true }
    },
    'ps256': {
        available: true,
        key: { alg: 'PS256', asymetric: true }
    },
    'ps384': {
        available: true,
        key: { alg: 'PS384', asymetric: true }
    },
    'ps512': {
        available: true,
        key: { alg: 'PS512', asymetric: true }
    },
    'rs256': {
        available: true,
        key: { alg: 'RS256', asymetric: true }
    },
    'rs384': {
        available: true,
        key: { alg: 'RS384', asymetric: true }
    },
    'rs512': {
        available: true,
        key: { alg: 'RS512', asymetric: true }
    }
}



const JWE_ALGORITHM_DETAIL = {
    'rsa-oaep': {
        available: true,
        'key': { 'alg': "RSA-OAEP", asymetric: true },
        'jwe': { 'alg': 'RSA-OAEP'}
    },
    'rsa-oaep-256': {
        available: true,
        'key': { 'alg': "RSA-OAEP-256", asymetric: true },
        'jwe': { 'alg': 'RSA-OAEP-256'}
    },
    'rsa-oaep-512': {
        available: true,
        'key': { 'alg': "RSA-OAEP-512", asymetric: true },
        'jwe': { 'alg': 'RSA-OAEP-512'}
    },
    'a128kw': {
        available: false,
        'key': { 'alg': 'A128KW', asymetric: false },
        'jwe': { 'alg': 'A128KW', 'enc': null }
    },
    'a256kw': {
        available: false,
        'key': { 'alg': 'A256KW', asymetric: false },
        'jwe': { 'alg': 'A256KW' }
    },
    'ecdh-es': {
        available: false,
        'key': { 'alg': 'ECDH-ES', asymetric: true },
        'jwe': { 'alg': 'ECDH-ES', 
            epk: null /* public key formato jwk exportada */,
            apu: null /* opc: agreement PartyUInfo Valor constante con el nombre del productor, p.e. key-dispatcher-ui*/,
            apv: null /* opc: agreement PartyVInfo Valor constante con el nombre del receptor, p.e. applicación receptora */
        }
    },
    'ecdh-es+a128kw': {
        available: false,
        'key': { 'alg': 'ECDH-ES+A128KW', asymetric: true },

        'jwe': { 'alg': 'ECDH-ES+A128KW', 
            epk: null /* public key formato jwk exportada */,
            apu: null /* opc: agreement PartyUInfo Valor constante con el nombre del productor, p.e. key-dispatcher-ui*/,
            apv: null /* opc: agreement PartyVInfo Valor constante con el nombre del receptor, p.e. applicación receptora */
        }
    },
    'ecdh-es+a192kw': {
        available: false,
        'key': { 'alg': 'ECDH-ES+A192KW', asymetric: true },
        'jwe': { 'alg': 'ECDH-ES+A192KW', 
            epk: null /* public key formato jwk exportada */,
            apu: null /* opc: agreement PartyUInfo Valor constante con el nombre del productor, p.e. key-dispatcher-ui*/,
            apv: null /* opc: agreement PartyVInfo Valor constante con el nombre del receptor, p.e. applicación receptora */
        }
    },
    'ecdh-es+a256kw': {
        available: false,
        'key': { 'alg': 'ECDH-ES+A256KW', asymetric: true },
        'jwe': { 'alg': 'ECDH-ES+A256KW'}
    },
    'a128gcmkw': {
        available: true,
        'key': { 'alg': 'A128GCMKW', asymetric: false },
        'jwe': { 'alg': 'A128GCMKW', 
            iv: null /* Utilizar crypto.randomBytes(size, callback)  */,
            tag: null /* Tag de autentificación generado durante la encriptación*/,
        }
    },
    'a256gcmkw': {
        available: true,
        'key': { 'alg': 'A256GCMKW', asymetric: false },
        'jwe': { 'alg': 'A256GCMKW', 
            iv: null /*  */,
            tag: null /* */,
        }
    }
}



const isEncrypter = (usages) => {
    return (['encrypt'].filter(v => usages.includes(v)).length > 0)
}


const isSigner = (usages) => {
    return (['sign'].filter(v => usages.includes(v)).length > 0)
}

const isJWT = (usages) => {
    return (['sign', 'verify'].filter(v => usages.includes(v)).length > 0)
}

const isJWE = (usages) => {
    return (['encrypt', 'decrypt'].filter(v => usages.includes(v)).length > 0)
}

const encryptsOrSigns = (key) => {
    return (['encrypt', 'sign'].filter(value => key.usages.includes(value)).length > 0)
}

const decryptsOrSigns = (key) => {
    return (['decrypt', 'verify'].filter(value => key.usages.includes(value)).length > 0)
}



export { isJWE, isJWT, isSigner, isEncrypter, encryptsOrSigns, decryptsOrSigns }
export  {JWS_ALGORITHM_DETAIL, JWE_ALGORITHM_DETAIL, JWE_ENC_ALGORITHM_DETAIL, KEY_USAGES }

