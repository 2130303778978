import { Flex, Heading, TextArea } from "@radix-ui/themes"
import { useDispatch, useSelector } from "react-redux"
import * as keyDispatcherReducer from "../redux/reducers/key-dispatcher-reducer"


export const JsonSourceArea = () => {

    let timeout = null
    const dispatch = useDispatch()
    const { credential, form } = useSelector(store => store.keyDispatcher)
    
    const waitToDispatch = (value) => {

        if (timeout) {
            clearTimeout(timeout)
        }

        timeout = setTimeout(() => {
            dispatch(keyDispatcherReducer.setPayload(value))
            dispatch(keyDispatcherReducer.createToken({credential, form, payload: value}))
        }, 750)

    }

    return (
        <Flex direction="column" gap="3" width="35%"> 
            <Heading as="h2">Payload</Heading>
            <TextArea rows="6" style={{height: "100%"}} onChange={e=> waitToDispatch(e.target.value)}></TextArea>
        </Flex>
    )
}