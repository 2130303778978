import keyDispatcherReducer from "./reducers/key-dispatcher-reducer"

const rootReducer = {
    keyDispatcher: keyDispatcherReducer,
    /*
    form: formReducer,
    credential: credentialReducer,
    encoder: encoderReducer
    */
}

export default rootReducer