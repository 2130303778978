import { useSelector } from "react-redux"
import * as keyHelper from '../services/key.helper'
import { Box, CheckboxGroup, Table } from "@radix-ui/themes"


export const UsageRow = ({usage}) => {

  return (
    <Table.Row>
      <Table.RowHeaderCell>{usage}</Table.RowHeaderCell>
        {
        ['secret', 'public_key', 'private_key']
          .map(item => (
            <Table.Cell key={`tc_${item}_${usage}`} align="center"><CheckboxGroup.Item
              key={`${item}_${usage}`} value={`${item}_${usage}`}></CheckboxGroup.Item></Table.Cell>
          ))
        }
    </Table.Row>
  )
}


export const UsageTable = () => {
    const { form } = useSelector(store => store.keyDispatcher)
    return (
      <Box>
        <CheckboxGroup.Root disabled={true} defaultValue={[]} value={form.usages}>
          <Table.Root>
            <Table.Header>
              <Table.Row>
                <Table.ColumnHeaderCell></Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Secret</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Public Key</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Private Key</Table.ColumnHeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                keyHelper.KEY_USAGES
                .filter(usage => usage.available)
                .map(item => <UsageRow key={item.text} usage={item.text}/>)
              }
            </Table.Body>
          </Table.Root>
        </CheckboxGroup.Root>
      </Box>
    )
  }

  